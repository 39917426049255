import { mapMutations } from 'vuex'
import unPay from 'public/src/pages/checkout/components/unifiedPay'
import apiReport from 'public/src/pages/common/apiReport'
import { htmlDecode } from '@shein/common-function'
import schttp from 'public/src/services/schttp'
import schttpSync from 'public/src/services/schttp/sync'
import GooglePay from 'public/src/pages/checkout/components/googlePay'
import applePayConfig from 'public/src/pages/common/apple_pay'
import { encryptOest } from '@shein-aidc/basis-oesthandle'
// import { getTaxesConfig } from 'public/src/pages/common/helpers.js'
import {
  getPOALineItems,
  getXtraAgainLineItems
} from 'public/src/pages/checkout/components/tools.js'
import errorReport from '@/public/src/pages/common/handlePay/log'
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'
import CodVerifyFreePopup from 'public/src/pages/common/codVerifyFreePopup'

const { langPath, host, WEB_CLIENT, APPLE_PAY_CONFIG } = gbCommonInfo
const applePayChannel = APPLE_PAY_CONFIG?.applePayChannel || applePayConfig.applePayChannel
const applePayTokenChannel = APPLE_PAY_CONFIG?.applePayTokenChannel || applePayConfig.applePayTokenChannel
const IS_RW = WEB_CLIENT === 'romwe'
const specialPayment = ['cod', 'klarna-sofortjs', ...GooglePay.googlePayMethods].concat(
  applePayChannel
)
// 特殊现金支付
// fix nuvei-konbini 返回异常链接跳转报错问题修复 - 不判断pay_url是否存在，统一走发起支付接口获取跳转链接
// 历史逻辑：现金支付时，如果pay_url存在，则直接跳转pay_url，否则走发起支付接口获取跳转链接，但这几种支付方式例外
const specialCashPayments = ['adyen-konbini', 'adyen-multibanco', 'nuvei-konbini']

import { daEventCenter } from 'public/src/services/eventCenter/index'
daEventCenter.addSubscriber({ modulecode: '1-19-1' })
daEventCenter.addSubscriber({ modulecode: '1-11-1' })

let pciFormParams = {}
let ltspcCsrf = ''

let getPciFormParams = async () => {
  if (typeof window == 'undefined') return

  let userEmailValue = await UserInfoManager.get({ key: 'pwa_user_email', actionType: 'paymentCbs' })
  const oest = UserInfoManager.get({ key: 'oest' })
  const enOest = encryptOest(oest)

  let user = gbCommonInfo.user
  pciFormParams = {
    SiteUID: gbCommonInfo.SiteUID,
    AppCurrency: gbCommonInfo.currency,
    Token: '',
    lang: gbCommonInfo.lang,
    siteFrom: IS_RW ? 'm' : 'pwa',
    email: userEmailValue,
    nickname: user?.nickname || '',
    originSite: host + langPath,
    enOest
  }
  if (IS_RW && window.localStorage && window.localStorage.setItem) {
    localStorage.setItem('IS_NEW_ROMWE', 1)
  }
}
await getPciFormParams()

async function jumpPCI (billno) {
  if (typeof window == 'undefined') return

  let formHtml = []
  $('.j-pci-form').remove()

  let _host = host + langPath
  if (gbCommonInfo.NODE_SERVER_ENV == 'localhost') {
    _host = 'http://localhost:' + (IS_RW ? '3007' : '5888')
    pciFormParams.originSite = _host + langPath
  }

  if (!ltspcCsrf) {
    await getUpdateCs()
  }
  pciFormParams._csrf = ltspcCsrf

  const oest = UserInfoManager?.get?.({ key: 'oest' })
  const enOest = encryptOest(oest)
  pciFormParams.enOest = enOest

  formHtml.push(
    '<form class="j-pci-form" name="pci_form_order" action="' +
      _host +
      '/ltspc/transformer/' +
      billno +
      '" method="post">'
  )
  for (var key in pciFormParams) {
    formHtml.push('<input type="hidden" name="' + key + '" value="' + pciFormParams[key] + '" />')
  }
  formHtml.push('<input type="submit" value="Continue" style="display:none;" />')
  $('body').append(formHtml.join(''))
  document.pci_form_order.submit()
}

function makeForm (params, action, method) {
  if (typeof window == 'undefined') return

  $(`form[name='pay_jump_form']`).remove()
  method = method || 'post'
  let $form = $('<form name="pay_jump_form" action="' + action + '" method="' + method + '"></form')
  for (const key in params) {
    let $input = $('<input type="hidden" />')
    $input.prop('name', key)
    $input.val(params[key])
    $form.append($input)
  }
  $form.append('<input type="submit" value="Continue" style="display:none;" />')
  $('body').append($form)
  document.pay_jump_form.submit()
}

async function getUpdateCs() {
  return new Promise(resolve => {
    //独立支付，csrf获取优化，和原来的获取方式并存
    schttp({
      method: 'GET',
      cache: false,
      url: '/ltspc/updateCs'
    })
      .then(res => {
        if (res && res.updateCs) {
          ltspcCsrf = res.updateCs
        }
        resolve()
      })
      .catch(() => {
        resolve()
      })
  })
}

export default {
  mounted() {
    getUpdateCs().then(() => {
      //检查独立支付的csrf是否有效
      ltspcCsrf &&
        schttp({
          url: '/ltspc/checkTransformer',
          method: 'POST',
          data: { _csrf: ltspcCsrf }
        })
          .then(() => {})
          .catch(e => {
            if (e && e.status == 403) {
              getUpdateCs()
            }
          })
    })
  },

  methods: {
    htmlDecode: text => htmlDecode({ text }),
    ...mapMutations('root', ['changeRootStatus', 'showMaskTips', 'changeUserRootSLoadingStatus']),
    ...mapMutations('orderDetail', ['assignState']),
    setPCIToken({ jump_pci_token = '', }) {
      pciFormParams.Token = jump_pci_token
      //pciFormParams._csrf = pci_csrf_token
    },
    paymentCbs: function (order) {
      var self = this

      var payMethod = order.payment_method
      pciFormParams.payCode = payMethod
      if (self.nowCheckoutScene === 'XtraDetail') {
        pciFormParams.isBuyXtra = 1
      }

      if (self.nowCheckoutScene === 'BuyPrime') {
        pciFormParams.isBuyPrime = 1
      }
      var pciValid = true
      // 如果pciHandler对应的支付方式是空数组，则全部站点去pci支付页，如果不是空数组，则对应的站点去pci页面
      // if (pciHandler[payMethod] && (!pciHandler[payMethod].length || pciHandler[payMethod].indexOf(gbCommonInfo.SiteUID) > -1)) {
      //   pciValid = true
      // }
      var billno = order.billno

      const payFailTips = {
        w_7514: this.language.SHEIN_KEY_PWA_16026,
        w_7517: this.language.SHEIN_KEY_PWA_16038,
        w_7519: this.language.SHEIN_KEY_PWA_16093,
        w_7520: this.language.SHEIN_KEY_PWA_16094,
        w_7521: this.language.SHEIN_KEY_PWA_16095,
        w_7522: this.language.SHEIN_KEY_PWA_16099,
        w_7523: this.language.SHEIN_KEY_PWA_16096,
        w_7524: this.language.SHEIN_KEY_PWA_16097,
        w_7525: this.language.SHEIN_KEY_PWA_16038,

        w_7532: this.language.SHEIN_KEY_PWA_16563,
        w_7533: this.language.SHEIN_KEY_PWA_16564,
        w_7534: this.language.SHEIN_KEY_PWA_16565,
        w_7535: this.language.SHEIN_KEY_PWA_16566,
        w_7536: this.language.SHEIN_KEY_PWA_16567,
        w_7537: this.language.SHEIN_KEY_PWA_16568,
        w_7538: this.language.SHEIN_KEY_PWA_16569,
        w_7539: this.language.SHEIN_KEY_PWA_16570,
        w_7540: this.language.SHEIN_KEY_PWA_16571,
        w_7541: this.language.SHEIN_KEY_PWA_16572,
        w_7542: this.language.SHEIN_KEY_PWA_16573,
        w_7544: this.language.SHEIN_KEY_PWA_16574,
        w_7545: this.language.SHEIN_KEY_PWA_16828
      }

      var errorHandle = function (res, billno, extraOptions) {
        self.changeRootStatus({
          loading: false
        })

        var popTExt = typeof extraOptions !== 'object' ? extraOptions : extraOptions?.errorMsg || ''
        if (!popTExt) {
          popTExt = self.language.SHEIN_KEY_PWA_16038
          if (res && res.info && res.info.error_code) {
            const error_code = res.info.error_code
            const show_error_msg = res.info.show_error_msg
            popTExt = payFailTips['w_' + error_code] || show_error_msg || popTExt
          }
        }
        self.updateHasGuide(Number(res?.info?.is_guide) === 1)
        self.$nextTick(() => {
          if (extraOptions?.isAddressError) {
            self.showMaskTips({
              type: 'addressError',
              billno,
              errorCode: res?.info?.error_code || '',
              text: '<span style="color:#f56262;word-break: break-word">' + popTExt + '</span>'
            })
          } else {
            self.showMaskTips(
              '<span style="color:#f56262;word-break: break-word">' + popTExt + '</span>'
            )
          }
        })
      }

      const closeLoading = function () {
        self.changeRootStatus({
          loading: false
        })
      }
      // 2017code支付接口整合逻辑
      // 非卡支付时，根据abt走2017接口整合
      let abtGoUnify = true

      var cbs = {
        cod: {
          cb: function () {
            // _gb_app_.$store.commit('changeRootStatus', { loading: false })
            // 前置cod免验证逻辑
            // 单个子订单场景下以G订单号请求；多个订单场景下以U订单号请求
            let validBillno = order?.billno
            // 兼容 目前只有一个子订单时不会返回sub_billnos
            if (
              order?.sub_billnos?.length === 1 &&
              order.sub_billnos[0].sub_billno
            ) {
              validBillno = order.sub_billnos[0].sub_billno
            }
            if (order?.sub_billnos?.length > 1) {
              validBillno = order?.relation_billno
            }
            schttp({
              url: '/api/pay/cod/queryNeedVerify/get',
              data: { billno: validBillno },
              method: 'POST'
            })
              .then(res => {
                closeLoading()
                console.log('cod verify', res)
                if (res && res.code == 0 && res.info) {
                  const { certificationFree } = res.info
                  if (certificationFree == 1 && self.codFreeShow !== 'Hide') {
                    // cod免验证弹窗逻辑
                    if (self.codFreeShow === 'Show1') {
                      CodVerifyFreePopup.createPopup({
                        needCountdown: false,
                        order
                      })
                    } else if (self.codFreeShow === 'Show2') {
                      CodVerifyFreePopup.createPopup({
                        needCountdown: true,
                        order
                      })
                    }
                  } else {
                    if (order.isLocalCurrency && order.localCodPrice) {
                      _gb_app_.$router.push(
                        langPath +
                          '/pay/cod?billno=' +
                          order.billno +
                          '&country_id=' +
                          order.country_id +
                          '&total=' +
                          order.localCodPrice.amountWithSymbol
                      )
                      // window.location.href = langPath + '/pay/cod?billno=' + order.billno + '&country_id=' + order.country_id + '&total=' + order.localCodPrice.amountWithSymbol
                    } else {
                      _gb_app_.$router.push(
                        langPath +
                          '/pay/cod?billno=' +
                          order.billno +
                          '&country_id=' +
                          order.country_id +
                          '&total=' +
                          order.totalPrice.amountWithSymbol
                      )
                      // window.location.href = langPath + '/pay/cod?billno=' + order.billno + '&country_id=' + order.country_id + '&total=' + order.totalPrice.amountWithSymbol
                    }
                  }
                }
              })
              .catch(e => {
                closeLoading()
                console.error(e)
              })
          },
          title: 'Cash On Delivery',
          className: 'cod'
        },
        'worldpay-card': {
          cb: function () {
            closeLoading()
            jumpPCI(billno)
          },
          title: this.language.SHEIN_KEY_PWA_15115,
          className: 'worldpay'
        },
        'Paytm-card': {
          cb: function () {
            closeLoading()
            var backurl = encodeURIComponent(
              host + langPath + '/pay/result/cb?type=Paytm&billno=' + order.billno
            )
            const device_language = navigator.language || navigator.browserLanguage || ''
            window.location.href =
              langPath +
              '/pay/paytm/' +
              order.billno +
              '?backurl=' +
              backurl +
              '&device_language=' +
              device_language
          },
          title: 'paytm',
          className: 'paytm'
        },
        'PayU-payu': {
          cb: function () {
            closeLoading()
            var backurl = encodeURIComponent(
              host + langPath + '/pay/result/cb?type=PayU&billno=' + order.billno
            )
            const device_language = navigator.language || navigator.browserLanguage || ''
            window.location.href =
              langPath +
              '/pay/payu/' +
              order.billno +
              '?backurl=' +
              backurl +
              '&device_language=' +
              device_language
          },
          className: 'payu'
        },
        'PayU-card': {
          cb: function () {
            closeLoading()
            var backurl = encodeURIComponent(
              host + langPath + '/pay/result/cb?type=PayU&billno=' + order.billno
            )
            const device_language = navigator.language || navigator.browserLanguage || ''
            window.location.href =
              langPath +
              '/pay/payu/' +
              order.billno +
              '?backurl=' +
              backurl +
              '&type=' +
              order.payment_method +
              '&device_language=' +
              device_language
          },
          className: 'PayU-card'
        },
        'PayU-netbank': {
          cb: function () {
            closeLoading()
            var backurl = encodeURIComponent(
              host + langPath + '/pay/result/cb?type=PayU&billno=' + order.billno
            )
            const device_language = navigator.language || navigator.browserLanguage || ''
            window.location.href =
              langPath +
              '/pay/payu/' +
              order.billno +
              '?backurl=' +
              backurl +
              '&type=' +
              order.payment_method +
              '&device_language=' +
              device_language
          },
          className: 'PayU-netbank'
        },
        'PayU-wallet': {
          cb: function () {
            closeLoading()
            var backurl = encodeURIComponent(
              host + langPath + '/pay/result/cb?type=PayU&billno=' + order.billno
            )
            const device_language = navigator.language || navigator.browserLanguage || ''
            window.location.href =
              langPath +
              '/pay/payu/' +
              order.billno +
              '?backurl=' +
              backurl +
              '&type=' +
              order.payment_method +
              '&device_language=' +
              device_language
          },
          className: 'PayU-wallet'
        },
        'PayU-wallets': {
          cb: function () {
            closeLoading()
            var backurl = encodeURIComponent(
              host + langPath + '/pay/result/cb?type=PayU&billno=' + order.billno
            )
            const device_language = navigator.language || navigator.browserLanguage || ''
            window.location.href =
              langPath +
              '/pay/payu/' +
              order.billno +
              '?backurl=' +
              backurl +
              '&type=' +
              order.payment_method +
              '&device_language=' +
              device_language
          },
          className: 'PayU-wallets'
        },
        'PayU-UPI': {
          cb: function () {
            closeLoading()
            var backurl = encodeURIComponent(
              host + langPath + '/pay/result/cb?type=PayU&billno=' + order.billno
            )
            const device_language = navigator.language || navigator.browserLanguage || ''
            window.location.href =
              langPath +
              '/pay/payu/' +
              order.billno +
              '?backurl=' +
              backurl +
              '&type=' +
              order.payment_method +
              '&device_language=' +
              device_language
          },
          className: 'PayU-UPI'
        },
        'PayU-googlepay': {
          cb: function () {
            closeLoading()
            var backurl = encodeURIComponent(
              host + langPath + '/pay/result/cb?type=PayU&billno=' + order.billno
            )
            const device_language = navigator.language || navigator.browserLanguage || ''
            window.location.href =
              langPath +
              '/pay/payu/' +
              order.billno +
              '?backurl=' +
              backurl +
              '&type=' +
              order.payment_method +
              '&device_language=' +
              device_language
          },
          className: 'PayU-googlepay'
        },
        'ebanx-card': {
          cb: function () {
            closeLoading()
            if (pciValid) {
              jumpPCI(billno)
            } else {
              window.location.href = langPath + '/pay/ebanx/card/' + order.billno
            }
          },
          title: 'ebanx-card',
          className: 'ebanx-card'
        },
        'checkout-cardjs': {
          cb: function () {
            closeLoading()
            jumpPCI(billno)
          },
          title: 'checkout-cardjs',
          className: 'checkout-cardjs'
        },
        'checkout-cardjs3ds': {
          cb: function () {
            closeLoading()
            jumpPCI(billno)
          },
          title: 'checkout-cardjs3ds',
          className: 'checkout-cardjs3ds'
        },
        'checkout-card3ds': {
          cb: function () {
            closeLoading()
            jumpPCI(billno)
          },
          title: 'checkout-card3ds',
          className: 'checkout-card3ds'
        },
        'routepay-card': {
          cb: function () {
            closeLoading()
            jumpPCI(billno)
          },
          title: 'routepay-card',
          className: 'routepay-card'
        },
        'dlocal-card': {
          cb: function () {
            closeLoading()
            jumpPCI(billno)
          },
          title: 'dlocal-card',
          className: 'dlocal-card'
        },
        'adyen-card3ds': {
          cb: function () {
            closeLoading()
            jumpPCI(billno)
          },
          title: 'adyen-card3ds',
          className: 'adyen-card3ds'
        },

        'worldpay-googlepay': {
          cb: function (code, callback) {
            if (typeof appEventCenter !== 'undefined') {
              // window.GB_orderVue = order
              googlePayment.setOrderInfo({
                ...order,
                totalPrice: order.totalPrice.amount
              })
              appEventCenter.$emit('showGooglePay', { code: 'worldpay-googlepay' })

              sa('send', {
                page_name: 'page_checkout_again',
                activity_name: 'expose_google_pay_popup',
                activity_param: ''
              })
            }

            callback && callback()
            // window.location.href = langPath + '/user/orders/detail/' + order.billno + '?googlepay=1'
          },
          title: 'worldpay-googlepay',
          className: 'worldpay-googlepay'
        },
        'adyen-googlepay': {
          cb: function (code, callback) {
            if (typeof appEventCenter !== 'undefined') {
              // window.GB_orderVue = order
              googlePayment.setOrderInfo({
                ...order,
                totalPrice: order.totalPrice.amount
              })
              appEventCenter.$emit('showGooglePay', { code: 'adyen-googlepay' })

              sa('send', {
                page_name: 'page_checkout_again',
                activity_name: 'expose_google_pay_popup',
                activity_param: ''
              })
            }

            callback && callback()
            // window.location.href = langPath + '/user/orders/detail/' + order.billno + '?googlepay=1'
          },
          title: 'adyen-googlepay',
          className: 'adyen-googlepay'
        },
        'nuvei-googlepay': {
          cb: function (code, callback) {
            if (typeof appEventCenter !== 'undefined') {
              // window.GB_orderVue = order
              googlePayment.setOrderInfo({
                ...order,
                totalPrice: order.totalPrice.amount
              })
              appEventCenter.$emit('showGooglePay', { code: 'nuvei-googlepay' })

              sa('send', {
                page_name: 'page_checkout_again',
                activity_name: 'expose_google_pay_popup',
                activity_param: ''
              })
            }

            callback && callback()
          },
          title: 'nuvei-googlepay',
          className: 'nuvei-googlepay'
        },
        'ebanx-mxcardinstallment': {
          cb: function () {
            closeLoading()
            jumpPCI(billno)
          },
          title: 'ebanx-mxcardinstallment',
          className: 'ebanx-mxcardinstallment'
        },
        'ebanx-brcardinstallment': {
          cb: function () {
            closeLoading()
            jumpPCI(billno)
          },
          title: 'ebanx-brcardinstallment',
          className: 'ebanx-brcardinstallment'
        },
        'ebanx-clcardinstallment': {
          cb: function () {
            closeLoading()
            jumpPCI(billno)
          },
          title: 'ebanx-clcardinstallment',
          className: 'ebanx-clcardinstallment'
        },
        'ingenico-card': {
          cb: function () {
            closeLoading()
            jumpPCI(billno)
          },
          title: 'ingenico-card',
          className: 'ingenico-card'
        },
        'klarna-sofortjs': {
          cb: function () {
            closeLoading()
            jumpPCI(billno)
          },
          title: 'klarna-sofortjs',
          className: 'klarna-sofortjs'
        },
        'worldpay-applepay': {
          cb: async function (orderInfo, vm) {
            // eslint-disable-next-line no-undef
            if (window.ApplePaySession && ApplePaySession.canMakePayments()) {
              self.changeRootStatus({
                loading: false
              })
              const paymentMethods = self.payments || []
              const applePaymentInfo = paymentMethods.find(item => item.code === order?.payment_method)
              console.log('applePaymentInfo', applePaymentInfo)
              let version = applePaymentInfo?.version ?? applePayConfig[order?.payment_method]?.version

              function applePayKibanaReport(
                { apiPath, code, extraData } = { apiPath: '', code: '', extraData: {} }
              ) {
                apiReport.report({
                  apiPath,
                  billno: orderInfo?.billno,
                  code,
                  paymentMethod: order?.payment_method,
                  ...(extraData || {})
                })
              }

              function getProductType () {
                if (self.nowCheckoutScene === 'XtraDetail') {
                  return 'paid_shein_saver'
                }
                if (self.nowCheckoutScene === 'BuyPrime') {
                  return 'paid_membership'
                }
                return 'normal'
              }

              function getCheckoutType () {
                if (self.nowCheckoutScene === 'XtraDetail') {
                  return 'xtra'
                }
                if (self.nowCheckoutScene === 'BuyPrime') {
                  return 'buyprime'
                }
                return 'normal'
              }
                    
              function getBaseReportInfo ({ order } = {}) {
                return {
                  uorder_id: order?.relation_billno,
                  order_no: order?.billno,
                  payment_method: order?.payment_method,
                  product_type: getProductType()
                }
              }
                    
              const baseReportInfo = getBaseReportInfo({ order: orderInfo })
                    
              function _sendErrorReport (payment_error_scene = '', params = {
                failure_type: '',
                client_url: '',
                status_code: '',
                description: ''
              }) {
                try {
                  errorReport.sendPaymentError(payment_error_scene, {
                    failure_type: params?.failure_type || '',
                    product_type: baseReportInfo.product_type,
                    payment_action_type: 'apple_pay',
                    client_url: params?.client_url || '',
                    error_data: {
                      status_code: params?.status_code,
                      uorder_id: baseReportInfo?.uorder_id,
                      order_no: baseReportInfo?.order_no,
                      payment_method: baseReportInfo?.payment_method,
                      description: params?.description || '',
                    }
                  })
                } catch (e) {
                  console.error(e)
                }
              }

              let applepayInfo = {}

              schttpSync({
                method: 'POST',
                url: '/api/pay/applepayInfo/get',
                data: { billno: order.billno, paymentCode: order.payment_method },
                success(res) {
                  applepayInfo = (res && res.info) || {}
                  applePayKibanaReport({
                    apiPath: 'pay/getApplepayInfo',
                    code: res?.code
                  })
                  if (res?.code != '0') {
                    _sendErrorReport('apple_pay_business_id_error', {
                      failure_type: 'api',
                      client_url: '/api/pay/applepayInfo/get',
                      status_code: res?.code || '',
                      description: `获取商户号配置接口失败 ${res?.tips || res?.msg || ''}`
                    })
                  }
                },
                error(err) {
                  applepayInfo = {}
                  applePayKibanaReport({
                    apiPath: 'pay/getApplepayInfo',
                    code: 'error'
                  })
                  _sendErrorReport('apple_pay_business_id_error', {
                    failure_type: 'api',
                    client_url: '/api/pay/applepayInfo/get',
                    status_code: err?.status || '',
                    description: `获取商户号配置接口失败 ${err?.message || ''}`
                  })
                }
              })
              const { applepayKey = '', appleCountryCode = '' } = applepayInfo
              const merchantIdentifier = applepayKey

              var request = {}
              const abtInfo = {
                applePayCashShow: self?.applePayCashShow
              }

              try {
                let lineItems = getPOALineItems({
                  language: self.language,
                  orderInfo,
                  abtInfo
                })
                // 超省卡单独购订单详情applepay金额明细
                if (self.nowCheckoutScene === 'XtraDetail') {
                  lineItems = getXtraAgainLineItems({
                    language: self.language,
                    order: orderInfo,
                    abtInfo
                  })
                }
                request = {
                  countryCode: appleCountryCode || orderInfo.countryCode,
                  currencyCode: orderInfo.currency_code,
                  merchantCapabilities: APPLE_PAY_CONFIG?.mapChannelToApplePaySupport?.[order?.payment_method]?.merchantCapabilities ?? applePayConfig[order?.payment_method]?.merchantCapabilities,
                  supportedNetworks: applePaymentInfo?.supportedNetworks?.length ? applePaymentInfo.supportedNetworks : applePayConfig[order?.payment_method]?.supportedNetworks,
                  total: {
                    label: IS_RW ? 'ROMWE' : 'SHEIN',
                    type: 'final',
                    amount: orderInfo.totalPrice.amount
                  }
                }

                // if applePayCashShow == 'Show1' 或者lineItems.length为0 不传lineItems
                if (lineItems.length && self?.applePayCashShow !== 'Show1') {
                  request.lineItems = lineItems
                }


                if (!orderInfo?.totalPrice?.amount) {
                  _sendErrorReport('apple_pay_get_total_price_error', {
                    failure_type: 'web',
                    client_url: '/web/error',
                    description: `获取总价失败 ${orderInfo?.totalPrice?.amount}`
                  })
                }
              } catch (e) {
                console.error(e)
                applePayKibanaReport({
                  apiPath: 'pay/applepay-line-items',
                  code: '0',
                  extraData: {
                    msg: (e && e.message) || '',
                    error: JSON.stringify(e || {})
                  }
                })
                _sendErrorReport('apple_pay_get_line_items_error', {
                  failure_type: 'web',
                  client_url: '/web/error',
                  description: `获取lineItems失败 ${e?.message || ''}`
                })
              }

              console.log('applepay request: ', request, 'version', version)

              console.log('applepay country: ', request.countryCode)

              try {
                // eslint-disable-next-line no-undef
                var session = new ApplePaySession(version, request)
                if (!session) {
                  _sendErrorReport('apple_pay_init_session_error', {
                    failure_type: 'sdk',
                    client_url: '/third/sdk/error',
                    description: `创建session失败`
                  })
                }
                session.onpaymentauthorized = function onpaymentauthorized(event) {
                  const device_language = navigator.language || navigator.browserLanguage || ''

                  let url = '/api/pay/unifiedPay/create'
                  let formData = {
                    billno,
                    device_language,
                    paymentCode: order.payment_method,
                    applePayInfo: applePayTokenChannel.includes(order.payment_method)
                      ? JSON.stringify(event.payment.token)
                      : JSON.stringify(event.payment.token.paymentData),
                    callbackUrl: host + langPath + '/pay/result/unifiedCb?bill_no=' + billno,
                    failureUrl: host + langPath + '/pay/result/unifiedCb?bill_no=' + billno,
                    pendingUrl: host + langPath + '/pay/result/unifiedCb?bill_no=' + billno,
                    forterDeviceFingerprintID: window.forterDeviceId || '',
                    forterSiteId: window.forterSiteId || '',
                    deviceFingerId: window.GB_cybersource_uuid || '',
                    childBillnoList:
                      order.sub_billnos && order.sub_billnos?.map(v => v.sub_billno).join(','), // 子订单号
                    checkout_type: getCheckoutType()
                  }
                  // 同盾指纹
                  if (window._fmOpt?.__blackbox) {
                    formData.blackbox = window._fmOpt.__blackbox
                  } else {
                    // 未获取到同盾指纹上报
                    apiReport.report({
                      apiPath: 'tongdun/before-payment/error',
                      sdk_init_status: window._fmOpt?.load_success ? 1 : 0, //同盾sdk初始化状态  1: 成功  0: 失败
                      billno: order.billno,
                      paymentMethod: order.payment_method
                    })
                  }
                  if (window.forterSDKLoaded && !window.forterDeviceId) {
                    //js初始化成功，且在发起支付时，前端给后端的指纹id为空
                    apiReport.report({
                      apiPath: 'forter/before-payment/error',
                      siteuid: gbCommonInfo.SiteUID,
                      scene: 'js sdk init success but forter deviceId is null',
                      billno: billno,
                      paymentMethod: order.payment_method
                    })
                  }
                  if (!window.GB_cybersource_uuid) {
                    // 非礼品卡，发起支付时，前端给后端的指纹id为空
                    apiReport.report({
                      apiPath: 'cybs/before-payment/error',
                      siteuid: gbCommonInfo.SiteUID,
                      scene: 'cybs deviceId is null',
                      billno: billno,
                      paymentMethod: order.payment_method
                    })
                  }

                  formData.neurPayId = billno + '_' + Math.floor(Math.random() * 99999)
                  formData.neurStep = 1

                  // eslint-disable-next-line no-undef
                  Object.assign(kafkaInfo, {
                    neurStep: formData.neurStep,
                    neurPayId: formData.neurPayId,
                    startTime: +new Date(),
                    callUrl: gbCommonInfo.host + url,
                    billno: billno,
                    paymentMethod: order.payment_method
                  })

                  const DEVICE_INFO = {
                    //acceptHeader: '',
                    colorDepth: window.screen.colorDepth,
                    javaEnabled: window.navigator.javaEnabled() ? 1 : 0,
                    language: window.navigator.language,
                    screenHeight: window.screen.height || 0,
                    screenWidth: window.screen.width || 0,
                    timeZoneOffset: new Date().getTimezoneOffset(),
                    userAgent: window.navigator.userAgent,
                    origin: window.location.origin
                  }

                  schttp({
                    url,
                    method: 'POST',
                    data: Object.assign(formData, DEVICE_INFO)
                  })
                    .then(res => {
                      // eslint-disable-next-line no-undef
                      kafkaInfo.sendData({ res, resStatus: 200, formData })

                      if (res?.code != 0 || res?.info?.error_code) {
                        _sendErrorReport('unified_pay_create_fail', {
                          failure_type: 'api',
                          client_url: '/pay/unifiedPay/create',
                          status_code: res?.info?.error_code || res?.code,
                          description: res?.info?.error_msg || res?.info?.show_error_msg
                        })
                      }

                      if (res.code == 0 && res.info) {
                        if (res.info.result == 1 && res.info.action == 'direct') {
                          // eslint-disable-next-line no-undef
                          const update = { status: ApplePaySession.STATUS_SUCCESS }
                          session.completePayment(update)
                          setTimeout(() => {
                            window.location.href =
                              host + langPath + '/pay/result/success?billno=' + billno
                          }, 1000)
                        } else if (res.info.action == 'render' && res.info.paramList) {
                          makeForm(res.info.paramList, res.info.actionUrl)
                        } else {
                          // eslint-disable-next-line no-undef
                          const update = { status: ApplePaySession.STATUS_FAILURE }
                          session.completePayment(update)
                          let show_error_msg =
                            '<span style="color:#f56262">' +
                            self.htmlDecode(this.language.SHEIN_KEY_PWA_15461) +
                            '</span>'
                          show_error_msg = res.info.show_error_msg || show_error_msg
                          vm.updateHasGuide(Number(res?.info?.is_guide) === 1)
                          vm.$nextTick(() => {
                            self.showMaskTips({
                              text: show_error_msg,
                              billno,
                              errorCode: res.info.error_code
                            })
                            daEventCenter.triggerNotice({
                              daId: '1-19-1-22',
                              extraData: {
                                billno,
                                error_code: res.info.error_code,
                                isDetailPage: location.pathname.includes('/detail/')
                              }
                            })
                          })
                        }
                      } else {
                        // eslint-disable-next-line no-undef
                        const update = { status: ApplePaySession.STATUS_FAILURE }
                        session.completePayment(update)
                        _sendErrorReport('apple_pay_result_error', {
                          failure_type: 'api',
                          client_url: '/api/pay/unifiedPay/create',
                          status_code: res?.code || '',
                          description: `调用统一支付接口失败 ${res?.tips || res?.msg || ''}`
                        })
                        self.showMaskTips(
                          '<span style="color:#f56262">' +
                            self.htmlDecode(this.language.SHEIN_KEY_PWA_15461) +
                            '</span>'
                        )
                      }
                    })
                    .catch(xhr => {
                      // eslint-disable-next-line no-undef
                      const update = { status: ApplePaySession.STATUS_FAILURE }
                      session.completePayment(update)
                      _sendErrorReport('apple_pay_result_error', {
                        failure_type: 'api',
                        client_url: '/api/pay/unifiedPay/create',
                        status_code: xhr?.status || '',
                        description: `调用统一支付接口失败 ${xhr?.message || ''}`
                      })
                      self.showMaskTips(
                        '<span style="color:#f56262">' +
                          self.htmlDecode(this.language.SHEIN_KEY_PWA_15461) +
                          '</span>'
                      )

                      let resStatus = 0 //默认超时，传0
                      if (xhr.status != 0) resStatus = xhr.status
                      // eslint-disable-next-line no-undef
                      kafkaInfo.sendData({ resStatus, formData })
                    })
                }
                session.onvalidatemerchant = function onvalidatemerchant(event) {
                  callStartSession(session, event.validationURL)
                }
                session.oncancel = function oncancel() {
                  applePayKibanaReport({
                    apiPath: 'pay/sessiononcancel'
                  })
                  _sendErrorReport('apple_pay_payment_cancel', {
                    failure_type: 'sdk',
                    client_url: '/third/sdk/error',
                    description: `渠道返回取消`
                  })
                  // window.location.href = host + langPath + '/user/orders/detail/' + billno;
                }
                session.begin()
              } catch (e) {
                self.assignState({
                  applePayShow: true
                })
                _sendErrorReport('apple_pay_init_session_error', {
                  failure_type: 'sdk',
                  client_url: '/third/sdk/error',
                  description: `创建session失败 ${e?.message || ''}`
                })
                console.error(e)
                applePayKibanaReport({
                  apiPath: 'pay/applepay-session-error',
                  code: '0',
                  extraData: {
                    msg: (e && e.message) || '',
                    error: JSON.stringify(e || {})
                  }
                })
              }
              // eslint-disable-next-line no-inner-declarations
              function callStartSession(session, url) {
                schttp({
                  url: '/api/pay/applepayauthorizemerchant/get',
                  method: 'POST',
                  data: { url: url, hostname: window.location.hostname, merchantIdentifier }
                }).then(data => {
                  const merchantSession = data

                  // Stop the session if merchantSession is not valid
                  if (typeof merchantSession === 'string' || 'statusCode' in merchantSession) {
                    applePayKibanaReport({
                      apiPath: 'pay/applepayauthorizemerchant',
                      code: 'error',
                      extraData: { merchantSession: JSON.stringify(merchantSession || {}) }
                    })
                    _sendErrorReport('apple_authorizationvc_error', {
                      failure_type: 'sdk',
                      client_url: '/third/sdk/error',
                      description: `验证商户号失败 statusCode:${merchantSession?.statusCode || merchantSession || ''} statusMessage:${merchantSession?.statusMessage || ''}`
                    })
                    cancelPaymentSession(session)
                    return
                  }
                  if (
                    !(
                      'merchantIdentifier' in merchantSession &&
                      'merchantSessionIdentifier' in merchantSession &&
                      ('nOnce' in merchantSession || 'nonce' in merchantSession)
                    )
                  ) {
                    applePayKibanaReport({
                      apiPath: 'pay/applepayauthorizemerchant',
                      code: 'error',
                      extraData: { merchantSession: JSON.stringify(merchantSession || {}) }
                    })
                    _sendErrorReport('apple_authorizationvc_error', {
                      failure_type: 'sdk',
                      client_url: '/third/sdk/error',
                      description: `验证商户号失败 statusCode:${merchantSession?.statusCode || merchantSession || ''} statusMessage:${merchantSession?.statusMessage || ''}`
                    })
                    cancelPaymentSession(session)
                    return
                  }

                  if (session !== null) {
                    applePayKibanaReport({
                      apiPath: 'pay/applepayauthorizemerchant',
                      code: '0'
                    })
                    session.completeMerchantValidation(merchantSession)
                  }

                  if (data && data.mid && window.kafkaInfo) {
                    // eslint-disable-next-line no-undef
                    kafkaInfo.applePayMid = data.mid
                  }
                })
              }
              // eslint-disable-next-line no-inner-declarations
              function cancelPaymentSession(session) {
                if (session !== null) {
                  session.abort()
                }
              }

              // applepay 金额明细税费展示处理
              // eslint-disable-next-line no-unused-vars
              // function handleTaxes(vm, taxes = []) {
              //   const language = vm.language || {}
              //   const { countryCode, country_id } = vm.orderInfo || {}
              //   // 是否是一单多税国家
              //   const isMultiTaxCountry =
              //     gbCommonInfo?.MULTIPLE_TAXES_SPLIT_DISPLAY?.includes(countryCode)
              //   // ca站特殊处理
              //   const isShowCaGST = country_id === '38' || gbCommonInfo?.SiteUID == 'ca'

              //   const formatTaxes = (taxes = []) => {
              //     // 巴西站特殊处理
              //     // wiki.pageId=1167728623
              //     const rewriteConfig =
              //       countryCode === 'BR'
              //         ? {
              //           3: {
              //             _name: 'II',
              //             langKey: 'SHEIN_KEY_PWA_25432'
              //           }
              //         }
              //         : {}
              //     let taxTypeConfig = getTaxesConfig({ rewriteConfig })
              //     if (!isMultiTaxCountry) {
              //       taxTypeConfig[1] = {
              //         ...taxTypeConfig[1],
              //         langKey: isShowCaGST ? 'SHEIN_KEY_PWA_23975' : 'SHEIN_KEY_PWA_16154'
              //       }
              //     }
              //     return taxes.map(tax => ({
              //       label: language?.[taxTypeConfig?.[tax?.taxType]?.langKey] || '',
              //       amount: tax.taxPrice?.amount
              //     }))
              //   }

              //   const personalTaxes = formatTaxes(
              //     taxes.filter(
              //       tax => tax.taxPrice && tax.taxPrice.amount > 0 && tax.taxPayType == 2
              //     )
              //   )
              //   const companyTaxes = formatTaxes(
              //     taxes.filter(
              //       tax => tax.taxPrice && tax.taxPrice.amount > 0 && tax.taxPayType == 1
              //     )
              //   )

              //   return {
              //     personalTaxes,
              //     companyTaxes
              //   }
              // }
            } else {
              // window.location.href = host + langPath + '/user/orders/detail/' + billno;
            }
          }
        },
        'adyen-card': {
          cb: function () {
            closeLoading()
            jumpPCI(billno)
          },
          title: this.language.SHEIN_KEY_PWA_15115,
          className: 'adyen-card'
        },
        'dlocal-brcardinstallment': {
          cb: function () {
            closeLoading()
            jumpPCI(billno)
          },
          title: 'dlocal-brcardinstallment',
          className: 'dlocal-brcardinstallment'
        },
        'dlocal-clcardinstallment': {
          cb: function () {
            closeLoading()
            jumpPCI(billno)
          },
          title: 'dlocal-clcardinstallment',
          className: 'dlocal-clcardinstallment'
        },
        'routepay-cardinstallment': {
          cb: function () {
            closeLoading()
            jumpPCI(billno)
          },
          title: 'routepay-cardinstallment',
          className: 'routepay-cardinstallment'
        },
        'paidy-paylater': {
          cb: function (_, callback) {
            closeLoading()
            callback && callback()
          },
          title: 'paidy-paylater',
          className: 'paidy-paylater'
        }
      }

      cbs['worldpay'] = cbs['worldpay-card']
      cbs['PayPal'] = cbs['PayPal-paypal']
      cbs['Paytm'] = cbs['Paytm-card']
      cbs['PayU'] = cbs['PayU-payu']
      applePayChannel.forEach(channel => {
        if (channel != 'worldpay-applepay') {
          cbs[channel] = cbs['worldpay-applepay']
        }
      })

      // 卡支付
      const cardPayMethods = ['dlocal-mxcardinstallment', 'ebanx-cocardinstallment']
      cardPayMethods.forEach(code => {
        cbs[code] = {
          cb() {
            closeLoading()
            jumpPCI(billno)
          },
          title: code,
          className: code
        }
      })

      if (this.payments && this.payments.length) {
        const paymentMethods = this.payments
        paymentMethods.forEach(item => {
          if (!cbs[item.code]) {
            cbs[item.code] = {
              title: item.code,
              className: item.code,
              cb() {}
            }
          }
        })

        if (!specialPayment.includes(payMethod) && paymentMethods && paymentMethods.length) {
          let paymentItem = paymentMethods.filter(item => item.code == payMethod)
          paymentItem = paymentItem[0] || {}

          // payment_type 代表不同支付类型：1-在线，2-现金，3-到付，5-延迟支付，6-卡支付，7-卡分期支付
          const paymentType = +paymentItem.payment_type || 0

          // paymentMethods.forEach(item => {
          //     if (!cbs[item.code]) {
          //       cbs[item.code] = {
          //         title: item.code,
          //         className: item.code,
          //         cb () {}
          //       }
          //     }
          // })

          // 非特殊支付和非卡支付的走生成订单后马上发起支付逻辑
          if (paymentType && ![6, 7].includes(paymentType) && abtGoUnify) {
            const checkoutType = this.nowCheckoutScene === 'XtraDetail' ? 'xtra' : ''
            const formData = unPay.checkForm({
              order,
              vm: this,
              errorHandle,
              makeForm,
              type: checkoutType
            })
            if (formData) {
              if (!cbs[payMethod]) {
                cbs[payMethod] = {}
              }
              cbs[payMethod].cb = function () {
                unPay.unifiedPay({
                  order,
                  vm: self,
                  errorHandle,
                  makeForm,
                  formData,
                  type: checkoutType
                })
              }
            }
          }

          // 待支付状态下现金支付条形码不刷新
          if (
            [0, 12].includes(self.orderInfo.orderStatus) &&
            paymentType === 2 &&
            self.originPaymentMethod.paymentMethod === self.paymentMethod.paymentMethod &&
            self.orderInfo.pay_url
          ) {
            if (!cbs[payMethod]) {
              cbs[payMethod] = {
                title: this.language.SHEIN_KEY_PWA_15115,
                className: payMethod,
                cb: function () {}
              }
            }
            cbs[payMethod].cb = function () {
              window.location.href = self.orderInfo.pay_url
            }
          }
        }
      }

      if (specialCashPayments.includes(payMethod)) {
        const checkoutType = this.nowCheckoutScene === 'XtraDetail' ? 'xtra' : ''
        const formData = unPay.checkForm({
          order,
          vm: this,
          errorHandle,
          makeForm,
          type: checkoutType
        })
        if (formData) {
          if (!cbs[payMethod]) {
            cbs[payMethod] = {}
          }
          cbs[payMethod].cb = function () {
            unPay.unifiedPay({
              order,
              vm: self,
              errorHandle,
              makeForm,
              formData,
              type: checkoutType
            })
          }
        }
      }

      return cbs
    }
  }
}
